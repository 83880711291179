import React, { useContext } from 'react';
import appContext from '../Context/appContext';
import { useDeliveryDetails } from '../hooks/app';
import notificationTypes from '../constants/modalNotificationConst';
import { isNewHomePageApplicable } from '../constants/feature';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../redux/slices/modalSlice';

export default function DeliveryTimingSchedule({ setShowNextWindow, showNextWindow }) {
  const {
    data: { scheduleDeliveryMessage, noScheduleSlotsMessage }
  } = useDeliveryDetails(false);
  const dispatchRedux = useDispatch()
  const notifications = useSelector(state => state.modal.notifications)

  const showAvailableDelivery = () => {
    const scheduleSlotOpen = notifications.map(notification => notification.type).includes(notificationTypes.scheduleSlot);
    if (!scheduleSlotOpen) {
      dispatchRedux(pushNotification({
        type: notificationTypes.scheduleSlot,
        data: { noScheduleSlotsMessage, hideRadioButtons: !isNewHomePageApplicable }
      }))
    }
  };

  return scheduleDeliveryMessage
    ? scheduleDeliveryMessage && (
      <div className='delivery-message-wrapper'>
        <div className="delivery-timing schedule-info d-flex  d-timing d-align-items-center">
          <p className="mb-0 next-slot d-flex flex-column">
            Next Delivery Window:
            <span className="next-slot-time">{scheduleDeliveryMessage}</span>
          </p>
          <a onClick={showAvailableDelivery} className="mb-0 time-table">
            Select a Delivery Window
          </a>
        </div>
        <button onClick={() => setShowNextWindow(!showNextWindow)} className='btn icon-close'></button>
      </div>
    )
    : null
}
