import Polyline from '@mapbox/polyline';

export const convertEstimatedTimeToLocal = (orderEta, returnDateObject) => {
  const date = new Date();
  const optionsLocal = { hour12: true, hour: '2-digit', minute: '2-digit' };
  date.setMinutes(date.getMinutes() + orderEta);

  if (returnDateObject) {
    return date;
  }

  return date.toLocaleTimeString('en-US', optionsLocal);
};

const getETA = (estimatedTime, returnDateObject) => {
  let hr = 0;
  let min = 0;
  if (estimatedTime) {
    const timeArray = estimatedTime.trim().split(' ');

    switch (timeArray.length) {
      case 2:
        if (timeArray[1] === 'hour' || timeArray[1] === 'hours') {
          hr = parseInt(timeArray[0], 10);
        }
        if (timeArray[1] === 'min' || timeArray[1] === 'mins') {
          min = parseInt(timeArray[0], 10);
        }
        break;

      case 4:
        hr = parseInt(timeArray[0], 10);
        min = parseInt(timeArray[2], 10);
        break;

      default:
        return estimatedTime;
    }
  }

  return convertEstimatedTimeToLocal(hr * 60 + min, returnDateObject);
};

export const parseRoute = (routes, returnDateObject) => {
  const distance = routes.legs[0].distance.text;
  const estimatedTime = getETA(routes.legs[0].duration.text, returnDateObject);

  // draw route on map
  const points = Polyline.decode(routes.overview_polyline.points);

  const sourceMarker = {
    latitude: points[points.length - 1][0],
    longitude: points[points.length - 1][1]
  };

  const destinationMarker = {
    latitude: points[0][0],
    longitude: points[0][1]
  };

  return {
    distance,
    estimatedTime,
    coordinates: points,
    sourceMarker,
    destinationMarker
  };
};

export const interpolator = (routeArray, fps, time) => {
  // temporary array of distance vlaues
  let sum = 0;
  const distanceArray = [];
  for (let i = 0; i < routeArray.length - 1; i += 1) {
    const x2x1 = routeArray[i + 1].lat() - routeArray[i].lat();
    const y2y1 = routeArray[i + 1].lng() - routeArray[i].lng();
    const value = parseInt(Math.sqrt(x2x1 * 2 + y2y1 * 2) * 1000000000, 10);
    sum += value;
    distanceArray.push(sum);
  }
  let arrCount;
  if (time) {
    arrCount = parseInt(fps * time, 10);
  } else {
    arrCount = parseInt(fps * (sum / 5000000), 10);
  }

  // existing values are inserted
  const interval = sum / arrCount;
  const finalArray = [];
  finalArray[0] = {
    latitude: routeArray[0].lat(),
    longitude: routeArray[0].lng()
  };
  for (let i = 1; i < routeArray.length - 1; i += 1) {
    const index = distanceArray[i - 1] / interval;
    finalArray[parseInt(index, 10)] = {
      latitude: routeArray[i].lat(),
      longitude: routeArray[i].lng()
    };
  }
  finalArray[arrCount] = {
    latitude: routeArray[routeArray.length - 1].lat(),
    longitude: routeArray[routeArray.length - 1].lng()
  };

  // array of known values is created
  const indexArray = [];
  indexArray.push(0);
  for (let i = 1; i < finalArray.length; i += 1) {
    if (finalArray[i]) {
      indexArray.push(i);
    }
  }
  indexArray.push(arrCount);

  // remaining values are inserted
  for (let i = 0; i < indexArray.length - 1; i += 1) {
    const firstIndex = indexArray[i];
    const secondIndex = indexArray[i + 1];
    if (secondIndex - firstIndex > 1) {
      const latdiff = finalArray[secondIndex].latitude - finalArray[firstIndex].latitude;
      const latinterval = latdiff / (secondIndex - firstIndex);

      const lngdiff = finalArray[secondIndex].longitude - finalArray[firstIndex].longitude;
      const lnginterval = lngdiff / (secondIndex - firstIndex);

      for (let j = firstIndex + 1; j < secondIndex; j += 1) {
        finalArray[j] = {
          latitude: finalArray[j - 1].latitude + latinterval,
          longitude: finalArray[j - 1].longitude + lnginterval
        };
      }
    }
  }

  const tempRoute = [];
  for (let index = 0; index < finalArray.length; index += 1) {
    const element = finalArray[index];
    const latlng = { lat: element.latitude, lng: element.longitude };
    tempRoute.push(latlng);
  }
  return { finalArray, sum, tempRoute };
};
