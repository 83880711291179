const RenderMinutes = ({ remainingMinutes, remainingHours }) => {
  let text = '';
  if (remainingMinutes) {
    text = `${remainingMinutes}m:`;
  } else if (remainingHours) {
    text = '0m:';
  }
  return text;
};

export default RenderMinutes;
