/* eslint radix: ["error", "as-needed"] */
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { SCHEDULE } from '../constants/deliveryType';
import { useDeliveryDetails } from '../hooks/app';

const RemainingTime = ({ setTab, initialDeliveryAddressDetails }) => {
  const {
    data: { asapEnabled = true, asapStartTime, asapTimer, asapReOpensAt, deliveryDays }
  } = useDeliveryDetails(initialDeliveryAddressDetails, true);

  const timeLeft = asapTimer;
  const [remainingTime, setRemainingTime] = useState(-1);
  const [asapClosed, setasapClosed] = useState(false);
  const interval = useRef(null);

  const remainingSeconds = remainingTime % 60;
  const remainingMinutes = parseInt(remainingTime / 60) % 60;
  const remainingHours = parseInt(remainingTime / 3600);

  const clearIntervals = () => {
    clearInterval(interval.current);
  };

  const renderMinutes = () => {
    let text = '';

    if (remainingMinutes) {
      text = `${remainingMinutes}m:`;
    } else if (remainingHours) {
      text = '0m:';
    }
    return text;
  };

  const renderSeconds = () => {
    let text = '';
    if (remainingSeconds) {
      if (remainingSeconds < 10) {
        text = `0${remainingSeconds}s`;
      } else {
        text = `${remainingSeconds}s`;
      }
    } else if (remainingMinutes) {
      text = '00s';
    }
    return text;
  };

  const goToSchedule = () => {
    setTab(SCHEDULE);
  };

  useEffect(() => {
    const { hours = 0, minutes = 0, seconds = 0 } = timeLeft || {};
    if (Object.keys(asapTimer).length) {
      setRemainingTime(hours * 3600 + minutes * 60 + seconds);
    }
  }, [timeLeft]);

  useEffect(() => {
    const calculateAndSetTime = () => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else if (remainingTime == 0) {
        setasapClosed(true);
      }
    };

    interval.current = setInterval(() => {
      calculateAndSetTime();
    }, 1000);

    return () => {
      clearIntervals();
    };
  }, [remainingTime]);
  if (!asapEnabled) {
    return (
      <div className="text-center p-2 remaining-time">
        <div className="asap-delivery-message-handle bold px-2">
          <div className="bold">ASAP Delivery is not available in your area.&nbsp;</div>
          <div>
            {!(deliveryDays && deliveryDays.length) && (
              <Fragment>
                {'See '}
                <a className="cursor-pointer text-underline text-primary" onClick={goToSchedule}>
                  Scheduled Menu
                </a>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (asapReOpensAt) {
    return (
      <div className="text-center p-2 remaining-time">
        <div className="asap-delivery-message-handle bold px-2">
          <div className="bold">
            {'ASAP Delivery is '}
            <span style={{ color: '#FF7F68' }}>closed!</span>
          </div>
          <div>
            {'Next delivery time '}
            {asapReOpensAt}
            {/* asapReopnes as */}
            {' PT. '}
            {deliveryDays && deliveryDays.length > 0 && (
              <Fragment>
                {'See '}
                <a className="cursor-pointer text-underline text-primary" onClick={goToSchedule}>
                  Scheduled Menu
                </a>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (asapClosed) {
    return (
      <div className="text-center p-2 remaining-time">
        <div className="asap-delivery-message-handle bold px-2">
          <div className="bold">
            {'ASAP Delivery is '}
            <span style={{ color: '#FF7F68' }}>closed!</span>
          </div>
          <div>
            {'Reopens Soon.'}
            {deliveryDays && deliveryDays.length > 0 && (
              <Fragment>
                {'See '}
                <a className="cursor-pointer text-underline text-primary" onClick={goToSchedule}>
                  Scheduled Menu
                </a>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (asapTimer && Object.keys(asapTimer).length && remainingHours < 1) {
    return (
      <div className="text-center p-2 remaining-time">
        <div className="asap-delivery-message-handle bold px-2">
          <div className="bold">ASAP Delivery is closing</div>
          <div>
            {'You have '}
            <span className="bold" style={{ color: '#FF7F68', fontVariantNumeric: 'tabular-nums' }}>
              {`${remainingHours > 0 ? `${remainingHours}h:` : ''}`}
              {renderMinutes()}
              {renderSeconds()}
            </span>
            {' until closing time to place your order'}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default RemainingTime;
