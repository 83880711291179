import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { DEFAULT_ZIPCODE } from '../../../constants/default';
// import { ASAP, SCHEDULE } from '../../../constants/deliveryType';
// import { isNewHomePageApplicable } from '../../../constants/feature';
import * as WEBSITES from '../constants/website';
// import { useDeliveryDetails } from '../../../hooks/app';

import RenderMinutes from './DeliveryTabs/RenderMinutes';
import RenderSeconds from './DeliveryTabs/RenderSeconds';
import RenderProdCount from './DeliveryTabs/RenderProdCount';
import { DEFAULT_ZIPCODE } from '../constants/default';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import { isNewHomePageApplicable } from '../constants/feature';
import { useDeliveryDetails } from '../hooks/app';

const testOtherDays = date => {
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].some(day => date.includes(day));
};

const DeliveryTypeToggle = ({
  currentTab,
  setTab,
  isAsapDisabled,
  isScheduleDisabled,
  className,
  asapProductsCount,
  scheduledProductsCount,
  // hideToggle,
  hideHeaderCount = false,
  setAsapCounterEnabled = () => { },
  setNoAsapSlots = () => { }
}) => {
  const [timeData, setTimeData] = React.useState({});
  const [remainingTime, setRemainingTime] = useState(-1);
  const [asapOpen, setasapOpen] = useState(false);
  const [hideDeliveryTabs, setHideDeliveryTabs] = useState(false);

  const interval = useRef(null);
  const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });

  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //     }
  //   }
  // } = useContext(appContext);
  const { deliveryDetails: { postcode = DEFAULT_ZIPCODE } } = useSelector(state => state.user)

  const {
    data: { asapReOpensAt, asapStartTime, asapEnabled, scheduleEnabled }
  } = useDeliveryDetails(false, true);
  const hideToggle = !asapEnabled || !scheduleEnabled;

  const clearIntervals = () => {
    clearInterval(interval.current);
  };

  useEffect(() => {
    if (asapReOpensAt && asapStartTime) {
      const currentTimeArray = currentTime.split(':');
      const asapTimeArray = asapStartTime.split(':');
      if (currentTimeArray.length === 3 && asapTimeArray.length === 3) {
        let currentTimeHigherFlag = false;
        let hourDifference = 0;
        let minutesDifference = 0;
        let secondsDifference = 0;
        const diff = parseInt(24 - currentTimeArray[0]) + parseInt(asapTimeArray[0]);

        if (
          testOtherDays(asapReOpensAt) ||
          (asapReOpensAt.toLowerCase().includes('tomorrow') &&
            (diff > 24 || (diff === 24 && parseInt(asapTimeArray[1]) >= parseInt(currentTimeArray[1]))))
        ) {
          setTimeData({});
          setHideDeliveryTabs(true);
          setNoAsapSlots(true);
          return;
        }

        if (currentTimeArray[0] > asapTimeArray[0]) {
          hourDifference = 24 - parseFloat(currentTimeArray[0]) + parseFloat(asapTimeArray[0]);
          currentTimeHigherFlag = false;
        } else if (currentTimeArray[0] == asapTimeArray[0] && currentTimeArray[1] > asapTimeArray[1]) {
          hourDifference = 23 - parseFloat(currentTimeArray[0]) + parseFloat(asapTimeArray[0]);
          currentTimeHigherFlag = true;
        } else {
          hourDifference = parseFloat(asapTimeArray[0]) - parseFloat(currentTimeArray[0]);
        }

        if (currentTimeArray[1] > asapTimeArray[1]) {
          minutesDifference = parseFloat(60 - currentTimeArray[1]) + parseFloat(asapTimeArray[1]);
          hourDifference -= currentTimeHigherFlag ? 0 : 1;
        } else {
          minutesDifference = parseFloat(asapTimeArray[1]) - parseFloat(currentTimeArray[1]);
        }

        if (currentTimeArray[2] > asapTimeArray[2]) {
          secondsDifference = parseFloat(60 - currentTimeArray[2]) + parseFloat(asapTimeArray[2]);
          minutesDifference -= 1;
        } else {
          secondsDifference = parseFloat(asapTimeArray[2]) - parseFloat(currentTimeArray[2]);
        }
        setNoAsapSlots(false);
        setTimeData({
          hours: hourDifference,
          minutes: minutesDifference,
          seconds: secondsDifference
        });
        setAsapCounterEnabled(true);
        if (setTab) setTab(SCHEDULE);
      }
    } else {
      setTimeData({});
      setAsapCounterEnabled(false);
      setNoAsapSlots(false);
      setHideDeliveryTabs(false);
    }
  }, [asapReOpensAt, asapStartTime]);

  const [deliveryTimeDetails] = useState({
    remainingSeconds: remainingTime % 60,
    remainingMinutes: parseInt(remainingTime / 60) % 60,
    remainingHours: parseInt(remainingTime / 3600)
  });

  const { remainingSeconds, remainingMinutes, remainingHours } = deliveryTimeDetails;

  useEffect(() => {
    (deliveryTimeDetails.remainingSeconds = remainingTime % 60),
      (deliveryTimeDetails.remainingMinutes = parseInt(remainingTime / 60) % 60),
      (deliveryTimeDetails.remainingHours = parseInt(remainingTime / 3600));
  }, [remainingTime]);

  // const remainingSeconds = remainingTime % 60;
  // const remainingMinutes = parseInt(remainingTime / 60) % 60;
  // const remainingHours = parseInt(remainingTime / 3600);

  useEffect(() => {
    const { hours = 0, minutes = 0, seconds = 0 } = timeData || {};
    if (Object.keys(timeData).length) {
      setRemainingTime(hours * 3600 + minutes * 60 + seconds);
    }
  }, [timeData]);

  useEffect(() => {
    const calculateAndSetTime = () => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else if (remainingTime == 0) {
        setasapOpen(true);
        setTimeData({});
        setAsapCounterEnabled(false);
      }
    };
    interval.current = setInterval(() => {
      calculateAndSetTime();
    }, 1000);

    return () => {
      clearIntervals();
    };
  }, [remainingTime, postcode]);
  const count = 0;

  // useEffect(() => {
  //   alert(count)
  //   if (!count) { count += count; return }
  //   mutate()
  // }, [postcode])

  if (isNewHomePageApplicable && !asapOpen && timeData && Object.keys(timeData).length) {
    return (
      <div className="asapcounter d-lg-flex">
        <div className="counterheader fs-12 fw-600 d-flex align-items-center justify-content-center">
          <div>
            <span>ASAP Back in </span>
            <div className="counter">
              {`${remainingHours > 0 ? `${remainingHours}h:` : '00h'}`}{' '}
              <RenderMinutes remainingMinutes={remainingMinutes} remainingHours={remainingHours} />
              <RenderSeconds remainingSeconds={remainingSeconds} remainingMinutes={remainingMinutes} />
            </div>
          </div>
        </div>
        {!isScheduleDisabled ? (
          <span className="scheduled-tab fs-14 bold d-flex align-items-center justify-content-center height-100">
            Scheduled <RenderProdCount productCount={scheduledProductsCount} isNewHomePageApplicable={isNewHomePageApplicable} />{' '}
          </span>
        ) : null}
      </div>
    );
  }
  if (hideToggle || hideDeliveryTabs) return null;
  return (
    <div className={`delivery-type-toggle${isNewHomePageApplicable ? '-v2' : ''} ${className}`}>
      <button
        type="button"
        data-testid="#asap-tab"
        className={`btn ${currentTab == ASAP ? 'd-selected' : 'not-selected'} `}
        onClick={() => {
          setTab(ASAP);
          window.scrollTo(0, 0);
        }}
        disabled={!!isAsapDisabled}
      >
        {WEBSITES.GRASSDOOR || process.env.NEXT_PUBLIC_DEFAULT_SITES ? 'ASAP' : 'Express'}
        <RenderProdCount productCount={asapProductsCount} />
      </button>
      <button
        type="button"
        data-testid="#schedule-tab"
        className={`btn ${currentTab == SCHEDULE ? 'd-selected' : 'not-selected'} `}
        onClick={() => {
          setTab(SCHEDULE);
          window.scrollTo(0, 0);
        }}
        disabled={!!isScheduleDisabled}
      >
        Scheduled <RenderProdCount productCount={scheduledProductsCount} />
      </button>
    </div>
  );
};

export default DeliveryTypeToggle;
