import * as PRODUCT_ATTR_TYPE from '../constants/productAttributeType';

function convertToMiligram(receivedValue, productUnit) {
  const value = Number(receivedValue);
  switch (productUnit) {
    case 'kg':
      return `${(value * 1000000).toFixed(1)}`;
    case 'g':
    case 'gram':
    case 'grams':
      return `${(value * 1000).toFixed(1)}`;
    case 'lb':
      return `${(value * 453592).toFixed(1)}`;
    case 'oz':
      return `${(value * 28350).toFixed(1)}`;
    case '1/8 oz':
      return `${(value * 3543).toFixed(1)}`;
    case '1/2 oz':
      return `${(value * 14174).toFixed(1)}`;
    case 'mg':
    default:
      return `${value.toFixed(1)}`;
  }
}

export default function getHighestValueAttribute(attributeArray, productWeight, productUnit) {
  const ratio = attributeArray.find(item => item.type === PRODUCT_ATTR_TYPE.ratio);
  if (ratio) {
    return [ratio];
  }

  let maxValue = 0;
  let maxElement = null;
  let isDefault = false;

  attributeArray.forEach(element => {
    switch (element.type) {
      case PRODUCT_ATTR_TYPE.percentage: {
        const weightInMiligram = (convertToMiligram(element.value, productUnit) * productWeight) / 100;
        if (maxValue < weightInMiligram) {
          maxElement = element;
          maxValue = weightInMiligram;
        }
        return;
      }
      case PRODUCT_ATTR_TYPE.range: {
        const rangeValue = element.value.split(',');
        if (rangeValue && rangeValue.length) {
          const weightInMiligram = (convertToMiligram(rangeValue[0], productUnit) * productWeight) / 100;
          if (maxValue < weightInMiligram) {
            maxElement = element;
            maxValue = weightInMiligram;
          }
        }
        return;
      }
      case PRODUCT_ATTR_TYPE.miligram: {
        const weightInMiligram = parseFloat(element.value);
        if (maxValue < weightInMiligram) {
          maxElement = element;
          maxValue = weightInMiligram;
        }
        break;
      }
      default: {
        isDefault = true;
        break;
      }
    }
  });
  if (isDefault) {
    return [];
  }
  return [maxElement];
}
