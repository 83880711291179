const RenderSeconds = ({ remainingSeconds, remainingMinutes }) => {
  let text = '';
  if (remainingSeconds) {
    if (remainingSeconds < 10) {
      text = `0${remainingSeconds}s`;
    } else {
      text = `${remainingSeconds}s`;
    }
  } else if (remainingMinutes) {
    text = '00s';
  }
  return text;
};
export default RenderSeconds;
